import React, { useState, useEffect } from 'react';
import useWebSocket from '../wsHook';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { sensorInfoAtom, SensorInfo, ParsedSensorInfo, Weather, RainGauge, RiverHeight, Tank, Kiln, SoilMt, JInfo, Esensor } from '../atoms-types/eSensors';
import { WidthProvider, Responsive } from 'react-grid-layout'
import { Divider, Card, Typography, CardContent, Box } from '@mui/material';
import EsensorChartHandler from '../handlers/eSensorGraph-handler';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const EnvironGrid: React.FC = () => {
  const { Group } = useParams();
  const [sensorInfo, setSensorInfo] = useAtom(sensorInfoAtom);
  const [selectedSensor, setSelectedSensor] = useState<{ deviceEUI: string; display: string[] } | null>(null);
  const { messages } = useWebSocket('wss://odp.golf/websocket', ['sensor']);

  // Add useEffect for websocket updates
  useEffect(() => {
    messages.forEach((messageObj) => {
      const { channel, message } = messageObj;
      try {
        if (channel === 'sensor' && message) {
          const { deviceEUI, ...jInfo } = message as Esensor;
          
          setSensorInfo((prev) => {
            if (!prev[deviceEUI]) {
              return prev;
            }
            
            const updatedSensors = prev[deviceEUI].map(sensor => ({
              ...sensor,
              jInfo
            }));

            return {
              ...prev,
              [deviceEUI]: updatedSensors
            };
          });
        }
      } catch (error) {
        console.error('Failed to process websocket message:', error);
      }
    });
  }, [messages]);

  // Query for sensor data
  const { data, isLoading } = useQuery({
    queryKey: ['sensorMetadata', Group],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<SensorInfo[]>(`https://odp.golf/api/sensor/${Group}`);
      const data = response.data;
      
      // Update the sensorInfo atom with data
      const newSensorInfo: { [deviceEUI: string]: SensorInfo[] } = {};
      data.forEach(sensor => {
        if (!newSensorInfo[sensor.deviceEUI]) {
          newSensorInfo[sensor.deviceEUI] = [];
        }
        newSensorInfo[sensor.deviceEUI].push(sensor);
      });
      // console.log("newSensorInfo", newSensorInfo);
      updateSensorInfo(newSensorInfo);
      
      return data;
    }
  });

  const updateSensorInfo = (newSensorInfo: { [deviceEUI: string]: SensorInfo[] }) => { 
    const updatedSensorInfo: { [deviceEUI: string]: ParsedSensorInfo[] } = {};
    
    Object.entries(newSensorInfo).forEach(([deviceEUI, sensors]) => {
      updatedSensorInfo[deviceEUI] = sensors.map(sensor => {
        try {
          const parsedSensor = {
            ...sensor,
            Class: typeof sensor.Class === 'string' ? JSON.parse(sensor.Class) : sensor.Class,
            Display: typeof sensor.Display === 'string' ? JSON.parse(sensor.Display) : sensor.Display,
            jInfo: typeof sensor.jInfo === 'string' ? JSON.parse(sensor.jInfo) : sensor.jInfo
          };
          return parsedSensor as ParsedSensorInfo;
        } catch (error) {
          console.error(`Error parsing sensor data for deviceEUI ${deviceEUI}:`, error);
          // Return a default JInfo object based on the sensor type
          const defaultSensor = {
            ...sensor,
            Class: typeof sensor.Class === 'string' ? JSON.parse(sensor.Class) : sensor.Class,
            Display: typeof sensor.Display === 'string' ? JSON.parse(sensor.Display) : sensor.Display,
            jInfo: {} as JInfo // Default empty JInfo object
          };
          return defaultSensor as ParsedSensorInfo;
        }
      });
    });
    
    console.log("Final updatedSensorInfo:", updatedSensorInfo);
    setSensorInfo(updatedSensorInfo);
  }

  // Type mapping for sensor types
  const sensorTypeMap: Record<string, JInfo> = {
    "Weather": {} as Weather,
    "RainGauge": {} as RainGauge,
    "RiverHeight": {} as RiverHeight,
    "Tank": {} as Tank,
    "Kiln": {} as Kiln,
    "SoilMt": {} as SoilMt
  };

  // Type guard function to get the correct sensor type
  const getSensorValue = (sensorData: JInfo, sensorType: string, display: string): number => {
    const type = sensorTypeMap[sensorType];
    if (!type) return 0;
    
    const value = (sensorData as typeof type)[display as keyof typeof type];
    return typeof value === 'number' ? value : 0;
  };

  // Create layout based on metadata
  const createLayout = (): Layout[] => {
    if (!sensorInfo) return [];

    const layouts: Layout[] = [];
    let x = 0;
    let y = 0;
    const maxX = 7.5;

    // Iterate over the keyed sensorInfo object
    Object.entries(sensorInfo).forEach(([deviceEUI, sensors]) => {
      sensors.forEach(sensor => {
        if (x >= maxX) {
          x = 0;
          y++;
        }
        layouts.push({
          i: `${deviceEUI}`,
          x,
          y,
          w: 1.5,
          h: 3,
          minW: 1,
          minH: 3
        });
        x += 1.5;
      });
    });

    // Add chart at the bottom
    layouts.push({
      i: 'SensorChart',
      x: 0,
      y: y + 1,
      w: 9,
      h: 6,
      minW: 6,
      minH: 6
    });

    return layouts;
  };

  const layout = createLayout();

  // Get the unit based on the display type
  const getUnit = (displayType: string) => {
    switch (displayType) {
      case 'TempC':
        return '°C';
      case 'TempF':
        return '°F';
      case 'Humidity':
        return '%';
      case 'WindAvg':
      case 'WindMax':
        return 'mph';
      case 'WindDir':
        return '°';
      case 'BaroPress':
        return 'hPa';
      case 'Height':
        return 'in';
      default:
        return '';
    }
  };

  // Get the color based on the display type
  const getColor = (displayType: string) => {
    switch (displayType) {
      case 'TempC':
      case 'TempF':
        return 'success.main';
      case 'Humidity':
        return 'warning.main';
      case 'WindAvg':
      case 'WindMax':
      case 'WindDir':
        return 'info.main';
      case 'BaroPress':
        return 'primary.main';
      case 'Height':
        return 'secondary.main';
      case 'Inches':
        return 'error.main';
      default:
        return 'primary.main';
    }
  };

  // Create a card component based on the display type and sensor data
  const createCard = (layoutId: string) => {
    if (!data) return null;

    // Special case for chart
    if (layoutId === 'SensorChart') {
      return (
        <div key="SensorChart" style={{ minHeight: "500px" }} className='card-gauge'>
          <Card style={{ minHeight: "500px", width: "100%" }}>
            <EsensorChartHandler 
              deviceEUI={selectedSensor?.deviceEUI || ""} 
              Display={selectedSensor?.display || []} 
            />
          </Card>
        </div>
      );
    }

    // Get deviceEUI from layoutId
    const deviceEUI = layoutId;
    
    // Find the sensor metadata from the keyed structure
    const sensors = sensorInfo[deviceEUI];
    if (!sensors || sensors.length === 0) return null;
    const sensor = sensors[0];

    // Get the value from the sensor's jInfo
    const sensorData = sensor.jInfo;
    if (!sensorData) return null;

    // Ensure Display is treated as an array of strings
    const displays = Array.isArray(sensor.Display) ? sensor.Display : [];

    return (
      <div 
        key={layoutId} 
        style={{ minHeight: "150px" }} 
        className='card-gauge'
      >
        <Card 
          key={`${layoutId}-${JSON.stringify(sensorData)}`}
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            width: '100%', 
            height: '100%',
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 6
            }
          }}
          onClick={() => {
            if (displays.length > 0) {
              setSelectedSensor({
                deviceEUI,
                display: sensor.Display
              });
            }
          }}
        >
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {displays.map((displayType: string) => {
                const value = getSensorValue(sensorData, sensor.Type, displayType).toFixed(2);
                return (
                  <Box 
                    key={displayType} 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      px: 1
                    }}
                  >
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {displayType}:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                      <Typography variant="body1" sx={{ color: getColor(displayType) }}>
                        {value}
                      </Typography>
                      <Typography variant="caption" sx={{ color: getColor(displayType), ml: 0.5 }}>
                        {getUnit(displayType)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ mt: 1 }}>
              <Divider sx={{ borderBottomWidth: '3px', borderColor: displays.length > 0 ? getColor(displays[0]) : 'primary.main' }} />
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 1 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    color: 'text.secondary'
                  }}
                >
                  {sensor.Class}
                </Typography>
                {sensor.Location && (
                  <>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>•</Typography>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        color: 'text.secondary'
                      }}
                    >
                      {sensor.Location}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
    >
      {layout.map(l => createCard(l.i))}
    </ResponsiveGridLayout>
  );
};

export default EnvironGrid;