import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';

interface DownloadButtonProps {
  data: any[];
  filename: string;
  timestampField: string;
  valueField?: string;
  valueFields?: string[];
  disabled?: boolean;
  timezone?: string;
}

export default function DownloadButton({ 
  data, 
  filename, 
  timestampField, 
  valueField,
  valueFields,
  disabled = false,
  timezone = 'America/New_York'
}: DownloadButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCSV = () => {
    if (!data.length) return;

    // Use valueFields if provided, otherwise fall back to valueField
    const fields = valueFields || (valueField ? [valueField] : []);
    const headers = [timestampField, ...fields];
    
    const csvContent = [
      headers.join(','),
      ...data.map((entry: any) => {
        // Convert timestamp to specified timezone
        const timestamp = moment(entry[timestampField]).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
        return `${timestamp},${fields.map((field) => entry[field]).join(',')}`;
      }),
    ].join('\n');

    downloadFile(csvContent, `${filename}.csv`, 'text/csv;charset=utf-8;');
    handleClose();
  };

  const downloadFile = (content: string, filename: string, type: string) => {
    const blob = new Blob([content], { type });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<DownloadIcon />}
        endIcon={<ExpandMoreIcon />}
        disabled={disabled || !data.length}
      >
        Download
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={downloadCSV}>Download as CSV</MenuItem>
      </Menu>
    </>
  );
} 