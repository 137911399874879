import React, { useState, useEffect } from 'react';
import { useSetAtom, useAtomValue } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ConvertTime from '../converters/timestampConverter';
import { DeviceEUIProps, eSensorChartAtom, EsensorChart } from '../atoms-types/eSensors';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment-timezone';
import DownloadButton from '../buttons/downloadButton';
import SensorGraph from '../charts-graphs/sensorGraph';
import GraphTimePicker from '../buttons/graphTimePicker';

export default function EsensorChartHandler({ deviceEUI, Display, Class }: DeviceEUIProps) {
  const setEsensorChart = useSetAtom(eSensorChartAtom);
  const esensorChartData = useAtomValue(eSensorChartAtom);
  const [startDateTime, setStartDateTime] = useState<Moment>(moment().subtract(24, 'hour').tz('America/New_York'));
  const [endDateTime, setEndDateTime] = useState<Moment>(moment().tz('America/New_York'));
  const [selectedRange, setSelectedRange] = useState<string>("Last 24 hours");

  const { refetch } = useQuery({
    queryKey: ['EsensorChart', deviceEUI, startDateTime, endDateTime],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      if (!deviceEUI) return [];
      const response = await axios.get(
        `https://odp.golf/api/esensorchart/${deviceEUI}`, {
          params: {
            start: startDateTime.toISOString(),
            end: endDateTime.toISOString()
          }
        }
      );
      const data = response.data;
      console.log("EsensorChart data", data);
      UpdateSensorGraph(data);
      return data;
    },
    enabled: !!deviceEUI && !!Display,
  });
    
  useEffect(() => {
    refetch();
  }, [deviceEUI, startDateTime, endDateTime, refetch]);

  function UpdateSensorGraph(data: { Timestamp: string; jInfo: string }[]) {
    const updatedEsensorChart: EsensorChart[] = data?.map((entry) => {
      const parsedJInfo = JSON.parse(entry.jInfo || '{}');
      // Create an object with all matching display values
      const displayValues = Array.isArray(Display) 
        ? Display.reduce((acc, key) => {
            const value = parsedJInfo[key] ?? null;
            acc[key] = typeof value === 'number' ? value : null;
            return acc;
          }, {} as Record<string, number | null>)
        : { [Display]: parsedJInfo[Display] ?? null };

      return {
        timestamp: ConvertTime(entry.Timestamp),
        ...displayValues
      };
    }) || [];
    
    setEsensorChart(prev => [...updatedEsensorChart]);
  }

  // const renderLoadingOrError = () => {
  //   if (isFetching) {
  //     return <p>Loading...</p>;
  //   } else if (isError) {
  //     return <p>Error: {error.message}</p>;
  //   }
  //   return null;
  // };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {deviceEUI && Display ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                {Class}
              </Typography>
            </Box>
            <SensorGraph deviceEUI={deviceEUI} Display={Display} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <CardContent className="dateTimePickerContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                <GraphTimePicker
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                  setStartDateTime={setStartDateTime}
                  setEndDateTime={setEndDateTime}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                />
                <DownloadButton
                  data={esensorChartData.map((item: EsensorChart) => ({
                    Timestamp: item.timestamp,
                    ...(Array.isArray(Display) ? Display.reduce((acc, key) => ({
                      ...acc,
                      [key]: item[key]
                    }), {}) : { [Display]: item[Display] })
                  }))}
                  filename={`${deviceEUI}_${Array.isArray(Display) ? Display.join('_') : Display}_${startDateTime.format('YYYY-MM-DD')}_to_${endDateTime.format('YYYY-MM-DD')}`}
                  timestampField="Timestamp"
                  valueField={Array.isArray(Display) ? Display.join(',') : Display}
                  disabled={!esensorChartData.length}
                  timezone="America/New_York"
                />
              </CardContent>
            </LocalizationProvider>
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h6" color="text.secondary">
              Click on a sensor card to view its data
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
