import { atom } from 'jotai';

export interface Charger {
  BuildingID: string;
  Timestamp: string;
  NChans: number;
  NCharging: number;
  NWaiting: number;
  CState: number[];
  CPower: number[];
  CEnergy: number[];
}
export interface BuildingIDProps {
  BuildingID: string | undefined;
}

export const chargingAtom = atom<{ [BuildingID: string]: Charger }>({});

export const toggleCharging = atom<'CPower' | 'CEnergy'>('CPower');