import { atom } from 'jotai';

export interface Power {
  BuildingID: string;
  Timestamp: string;
  PhA: number;
  PhB: number;
  PhC: number;
  Grid: number;
  Load: number;
  Solar: number;
  Demand: number;
  DayPeak: number;
  PeakTime: number;
  DaykWhNet: number;
  DaykWhImport: number;
  DaykWhExport: number;
}
export const powerAtom = atom<{ [BuildingID: string]: Power[] }>({});
// export const buildingAtom = atom<Building[]>([]);