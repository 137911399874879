import React from 'react';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import { chargingAtom, BuildingIDProps } from '../atoms-types/chargers';
import { useAtom } from 'jotai';

export const ChargingCard: React.FC<BuildingIDProps> = ({BuildingID}) => {
  const [chargingData,] = useAtom(chargingAtom);

  if (BuildingID === undefined) { 
    return null;
  }

  const data = chargingData[BuildingID];

  if (!data) {
    return <Typography>Loading charging data for {BuildingID}...</Typography>;
  }

  return (
    <>
      {chargingData &&
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {BuildingID}:
            </Typography>
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Charging</Typography>
                <Chip 
                  label={`${data.NCharging}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Waiting</Typography>
                <Chip 
                  label={`${data.NWaiting}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
            </div>
          </CardContent>
        </Card>
      }
    </>
  );
};