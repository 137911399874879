import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Checkbox, styled, tableCellClasses } from '@mui/material';
import { eventsAtom, Event } from '../atoms-types/events';

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'dimgrey',
    color: theme.palette.common.white,
    '&:hover': {
      color: 'goldenrod',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EventTable: React.FC = () => {
  const [events, setEvents] = useAtom(eventsAtom);
  const [order, setOrder] = useState<Order>('desc'); // Default to descending order
  const [orderBy, setOrderBy] = useState<keyof Event>('EventNumber'); // Default sorting by EventNumber

  const handleRequestSort = (property: keyof Event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedEvents = [...events].sort((a, b) => {
    return order === 'desc' 
      ? (b[orderBy] as number) - (a[orderBy] as number) 
      : (a[orderBy] as number) - (b[orderBy] as number);
  });


  const handleAcknowledgeChange = (eventNumber: number) => {
    setEvents(prevEvents =>
      prevEvents.map(event =>
        event.EventNumber === eventNumber ? { ...event, Acknowledge: !event.Acknowledge } : event
      )
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            {[
              'EventNumber',
              'Timestamp',
              'UID',
              'DeviceName',
              'Type',
              'Location',
              'Message',
              'Detail',
              'Action',
              'Acknowledge',
              'Level',
              'Recipients',
            ].map((column) => (
              <StyledTableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'desc'}
                  onClick={() => handleRequestSort(column as keyof Event)}
                >
                  {column}
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {sortedEvents.map((event) => (
            <StyledTableRow key={event.EventNumber}>
              <TableCell>{event.EventNumber}</TableCell>
              <TableCell>{event.Timestamp}</TableCell>
              <TableCell>{event.UID}</TableCell>
              <TableCell>{event.DevName}</TableCell>
              <TableCell>{event.Type}</TableCell>
              <TableCell>{event.Location}</TableCell>
              <TableCell>{event.Message}</TableCell>
              <TableCell>{event.Detail}</TableCell>
              <TableCell>{event.Action}</TableCell>
              <TableCell>
                <Checkbox
                  checked={event.Acknowledge}
                  onChange={() => handleAcknowledgeChange(event.EventNumber)}
                />
              </TableCell>
              <TableCell>{event.Level}</TableCell>
              <TableCell>{event.Recipients}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventTable;
