import { atom } from 'jotai';

export interface Event {
  EventNumber: number;
  Seq: number;
  Timestamp: string;
  UID: string;
  DevName: string;
  Type: string;
  Location: string;
  Message: string;
  Detail: string;
  Action: string;
  Acknowledge: boolean;
  Level: number;
  Recipients: string;
}

export const eventsAtom = atom<Event[]>([]);