import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChargingStationIcon from '@mui/icons-material/EvStation';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import MapIcon from '@mui/icons-material/Map';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import HandymanIcon from '@mui/icons-material/Handyman';
import CloudIcon from '@mui/icons-material/Cloud';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LandslideIcon from '@mui/icons-material/Landslide';
import PowerIcon from '@mui/icons-material/Power';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { type Navigation } from '@toolpad/core/AppProvider';

const Base = [
  {
    segment: 'fleet',
    title: 'Fleet',
    icon: <BikeScooterIcon />,
    children: [
      {
        segment: 'charging',
        title: 'Charging',
        icon: <ChargingStationIcon />,
        children: [
          // {
          //   segment: '',
          //   title: 'All',
          // },
          {
            segment: 'pool',
            title: 'Poolhouse',
          },
          {
            segment: 'pavilion',
            title: 'Pavilion',
          },
          {
            segment: 'test',
            title: 'Test',
          }
        ]
      },
      {
        segment: 'map',
        title: 'Map',
        icon: <MapIcon />,
      }
    ]
  }
]

export const StaffNav: Navigation = [
  ...Base
]

export const AdminNav: Navigation = [
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Course',
  },
  ...Base,
  {
    segment: 'maintenance',
    title: 'Maintenance',
    icon: <HandymanIcon />,
    children: [
      {
        segment: 'charging',
        title: 'Charging',
        icon: <ChargingStationIcon />,
        children: [
        ]
      },
      {
        segment: 'map',
        title: 'Map',
        icon: <MapIcon />,
      }
    ]
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Utilities',
  },
  {
    segment: 'environment',
    title: 'Environment',
    icon: <DeviceThermostatIcon />,
    children: [
      {
        segment: 'weather',
        title: 'Weather',
        icon: <CloudIcon />,
      },
      {
        segment: 'water',
        title: 'Water',
        icon: <WaterDropIcon />,
      },
      {
        segment: 'soil',
        title: 'Soil',
        icon: <LandslideIcon />,
      },
    ]
  },
  {
    segment: 'power',
    title: 'Power',
    icon: <PowerIcon />,
    children: [
      {
        segment: 'pool',
        title: 'Poolhouse',
      },
      {
        segment: 'pavilion',
        title: 'Pavilion',
      },
      {
        segment: 'test',
        title: 'Test',
      }
    ]
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Facilities',
  },
  {
    segment: 'building',
    title: 'Building Management',
    icon: <CorporateFareIcon />,
        children: [
      {
        segment: 'tank',
        title: 'Tank and Kiln',
      },
    ]
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Events and Notifications',
  },
  {
    segment: 'events',
    title: 'Events',
    icon: <PriorityHighIcon/>,
  },
]