import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import moment, { Moment } from "moment-timezone";

interface GraphTimePickerProps {
  startDateTime: Moment;
  endDateTime: Moment;
  setStartDateTime: (date: Moment) => void;
  setEndDateTime: (date: Moment) => void;
  selectedRange: string;
  setSelectedRange: (label: string) => void;
}

const timeRanges = [
  { label: "Last 6 hours", hours: 6 },
  { label: "Last 12 hours", hours: 12 },
  { label: "Last 24 hours", hours: 24 },
  { label: "Last 3 days", hours: 72 },
  { label: "Last 7 days", hours: 168 },
  { label: "Last 30 days", hours: 720 }
];

const GraphTimePicker: React.FC<GraphTimePickerProps> = ({
  startDateTime,
  endDateTime,
  setStartDateTime,
  setEndDateTime,
  selectedRange,
  setSelectedRange
}) => {
  const updateTimeRange = (hours: number) => {
    // Create a single reference point in the current timezone
    const now = moment().tz('America/New_York');
    const newStart = now.clone().subtract(hours, "hour");
    const newEnd = now.clone();
    
    // Update both times atomically
    setStartDateTime(newStart);
    setEndDateTime(newEnd);
  };

  const handleRangeChange = (event: SelectChangeEvent<string>) => {
    const selected = event.target.value;
    const range = timeRanges.find((r) => r.label === selected);
    
    if (range) {
      // Update the range first
      setSelectedRange(selected);
      // Then update the times
      updateTimeRange(range.hours);
    }
  };

  const handleStartDateTimeChange = (newValue: Moment | null) => {
    if (newValue) {
      const tzValue = newValue.clone().tz('America/New_York');
      setStartDateTime(tzValue);
      setSelectedRange("Custom");
    }
  };

  const handleEndDateTimeChange = (newValue: Moment | null) => {
    if (newValue) {
      const tzValue = newValue.clone().tz('America/New_York');
      setEndDateTime(tzValue);
      setSelectedRange("Custom");
    }
  };

  return (
    <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <FormControl size="small">
        <InputLabel id="time-range-label">Time Range</InputLabel>
        <Select
          labelId="time-range-label"
          value={selectedRange}
          onChange={handleRangeChange}
          label="Time Range"
        >
          {timeRanges.map((range) => (
            <MenuItem key={range.label} value={range.label}>
              {range.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DateTimePicker
        label="Start DateTime"
        value={startDateTime}
        onChange={handleStartDateTimeChange}
        className="dateTimePickerInput"
        slotProps={{
          textField: {
            className: "dateTimePickerInput",
            inputProps: {
              className: "dateTimePickerInput"
            }
          },
          popper: {
            className: "dateTimePickerPopper"
          }
        }}
      />
      <DateTimePicker
        label="End DateTime"
        value={endDateTime}
        onChange={handleEndDateTimeChange}
        className="dateTimePickerInput"
        slotProps={{
          textField: {
            className: "dateTimePickerInput",
            inputProps: {
              className: "dateTimePickerInput"
            }
          },
          popper: {
            className: "dateTimePickerPopper"
          }
        }}
      />
    </div>
  );
};

export default GraphTimePicker;



