import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useAtom } from 'jotai';
import { eSensorChartAtom, DeviceEUIProps } from '../atoms-types/eSensors';
import moment from 'moment-timezone';

const SensorGraph: React.FC<DeviceEUIProps> = ({ deviceEUI, Display }) => {
  const [esensorChart] = useAtom(eSensorChartAtom);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      const chartElement = document.querySelector('.echarts-for-react');
      if (chartElement) {
        const echartsInstance = (chartElement as any).__ECharts__;
        if (echartsInstance) {
          echartsInstance.resize();
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const timestamps: string[] = [];
  const verticalLines: any[] = [];
  let previousDate = "";

  esensorChart.forEach((item, index) => {
    const timestamp = moment.utc(item.timestamp).tz('America/New_York');
    const formattedTime = timestamp.format('HH:mm:ss');
    const currentDate = timestamp.format('M/D');

    timestamps.push(formattedTime);

    if (currentDate !== previousDate && timestamp.hour() === 0 && timestamp.minute() === 0) {
      verticalLines.push({
        xAxis: index,
        lineStyle: {
          color: 'lightgrey',
          type: 'dashed',
        },
        label: {
          formatter: currentDate,
          position: 'end',
          fontSize: 10,
          color: 'grey',
        },
        tooltip: {
          formatter: `Date: ${currentDate}`,
        },
      });
      previousDate = currentDate;
    }
  });

  const colors = ['gold', 'firebrick', 'steelblue', 'slateblue', 'seagreen', 'purple', 'orange', 'crimson'];

  const series = Array.isArray(Display) ? Display.map((display, index) => {
    const values = esensorChart.map((item) => item[display]);
    const color = colors[index % colors.length];

    return {
      name: display,
      type: 'line',
      data: values,
      lineStyle: {
        color: color,
      },
      itemStyle: {
        color: color,
      },
      smooth: true,
      tooltip: {
        valueFormatter: function (value: number | null | undefined) {
          if (value === null || value === undefined) {
            return 'No data';
          }
          return value.toString();
        },
      },
      // Only add vertical date lines to the first series
      ...(index === 0 ? {
        markLine: {
          symbol: 'none',
          data: verticalLines,
        }
      } : {})
    };
  }) : [];

  const option = {
    tooltip: {
      show: !isMobile,
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
    },
    legend: {
      data: series.map(s => s.name),
      textStyle: {
        color: 'grey',
      },
      bottom: 0,
      padding: [5, 0, 0, 0],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: timestamps,
      axisLabel: {
        color: 'text.secondary',
        rotate: 45,
      },
      name: 'Time',
      nameTextStyle: {
        color: 'grey',
      },
    },
    yAxis: {
      type: 'value',
      name: 'Value',
      axisLabel: {
        formatter: '{value}',
        color: 'text.secondary',
      },
      nameTextStyle: {
        color: 'grey',
      },
    },
    series: series,
  };

  useEffect(() => {
    const chartElement = document.querySelector('.echarts-for-react');
    if (chartElement) {
      const echartsInstance = (chartElement as any).__ECharts__;
      if (echartsInstance) {
        echartsInstance.clear();
        echartsInstance.setOption(option);
      }
    }
  }, [Display, esensorChart]);

  return (
    <ReactECharts
      key={`${deviceEUI}-${Array.isArray(Display) ? Display.join('-') : Display}`}
      option={option}
      className="cancelSelectorName"
      style={{ height: '400px', width: '100%' }}
      opts={{ renderer: 'canvas' }}
    />
  );
};

export default SensorGraph;
