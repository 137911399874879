import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import axios from 'axios';
import { powerAtom, Power } from '../atoms-types/power';
import { BuildingIDProps } from '../atoms-types/chargers';
import BuildingGraph from '../charts-graphs/powerGraph';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment-timezone';
import { Card, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DownloadButton from '../buttons/downloadButton';
import { DateTimePicker } from "@mui/x-date-pickers";
import { SelectChangeEvent } from '@mui/material';
// import GraphTimePicker from '../buttons/graphTimePicker';

const timeRanges = [
  { label: "Last 6 hours", hours: 6 },
  { label: "Last 12 hours", hours: 12 },
  { label: "Last 24 hours", hours: 24 },
  { label: "Last 3 days", hours: 72 },
  { label: "Last 7 days", hours: 168 },
  { label: "Last 30 days", hours: 720 }
];

const PowerGraphHandler: React.FC<BuildingIDProps> = ({ BuildingID }) => {
  const [powerData, setPowerData] = useAtom(powerAtom);

  const [startDateTime, setStartDateTime] = useState<Moment>(moment().subtract(24, 'hour').tz('America/New_York'));
  const [endDateTime, setEndDateTime] = useState<Moment>(moment().tz('America/New_York'));
  const [selectedRange, setSelectedRange] = useState<string>("Last 24 hours");


  // Effect to fetch data when DateTime values change
  useEffect(() => {
    refetch();
  }, [startDateTime, endDateTime]);

  const { isFetching, isError, error, refetch } = useQuery({
    queryKey: ['powerGraph', BuildingID, startDateTime, endDateTime],
    staleTime: Infinity,
    queryFn: async () => {
      console.log("running query with: ", BuildingID);
      const response = await axios.get<Power[]>('https://odp.golf/api/powerdata-graph', {
        params: {
          BuildingID: BuildingID,
          start: startDateTime.toISOString(),
          end: endDateTime.toISOString(),
        },
      });
      const data = response.data;
      updatePowerData(data);
      return data;
    },
  });

  function updatePowerData(data: Power[]) {
    if (!data || data.length === 0) {
      console.error("No data available to update power information");
      return;
    }

    console.log("All fallback power data:", data);

    const parsedData = data.reduce((acc, powerData) => {
      if (!powerData.BuildingID) return acc;

      const buildingID = powerData.BuildingID.toLowerCase();

      // If the building already exists, push to its array; otherwise, create a new array
      if (!acc[buildingID]) {
        acc[buildingID] = [];
      }
      
      acc[buildingID] = [...data];

      return acc;
    }, {} as Record<string, Power[]>);

    setPowerData((prev) => ({
      ...prev,
      ...parsedData,
    }));
  }

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

    const updateTimeRange = (hours: number) => {
    // Create a single reference point in the current timezone
    const now = moment().tz('America/New_York');
    const newStart = now.clone().subtract(hours, "hour");
    const newEnd = now.clone();
    
    // Update both times atomically
    setStartDateTime(newStart);
    setEndDateTime(newEnd);
  };

  const handleRangeChange = (event: SelectChangeEvent<string>) => {
    const selected = event.target.value;
    const range = timeRanges.find((r) => r.label === selected);
    
    if (range) {
      // Update the range first
      setSelectedRange(selected);
      // Then update the times
      updateTimeRange(range.hours);
    }
  };

  const handleStartDateTimeChange = (newValue: Moment | null) => {
    if (newValue) {
      const tzValue = newValue.clone().tz('America/New_York');
      setStartDateTime(tzValue);
      setSelectedRange("Custom");
    }
  };

  const handleEndDateTimeChange = (newValue: Moment | null) => {
    if (newValue) {
      const tzValue = newValue.clone().tz('America/New_York');
      setEndDateTime(tzValue);
      setSelectedRange("Custom");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {renderLoadingOrError()}
      <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
        <BuildingGraph BuildingID = {BuildingID} />
        <CardContent className="dateTimePickerContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <FormControl size="small">
              <InputLabel id="time-range-label">Time Range</InputLabel>
              <Select
                labelId="time-range-label"
                value={selectedRange}
                onChange={handleRangeChange}
                label="Time Range"
              >
                {timeRanges.map((range) => (
                  <MenuItem key={range.label} value={range.label}>
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DateTimePicker
              label="Start DateTime"
              value={startDateTime}
              onChange={handleStartDateTimeChange}
              className="dateTimePickerInput"
              slotProps={{
                textField: {
                  className: "dateTimePickerInput",
                  inputProps: {
                    className: "dateTimePickerInput"
                  }
                },
                popper: {
                  className: "dateTimePickerPopper"
                }
              }}
            />
            <DateTimePicker
              label="End DateTime"
              value={endDateTime}
              onChange={handleEndDateTimeChange}
              className="dateTimePickerInput"
              slotProps={{
                textField: {
                  className: "dateTimePickerInput",
                  inputProps: {
                    className: "dateTimePickerInput"
                  }
                },
                popper: {
                  className: "dateTimePickerPopper"
                }
              }}
            />
          </div>
          <DownloadButton
            data={(powerData[BuildingID as string] || []).map(item => ({
              Timestamp: item.Timestamp,
              Grid: item.Grid,
              Load: item.Load,
              Solar: item.Solar,
              Demand: item.Demand,
              DayPeak: item.DayPeak
            }))}
            filename={`${BuildingID}_power_${startDateTime.format('YYYY-MM-DD')}_to_${endDateTime.format('YYYY-MM-DD')}`}
            timestampField="Timestamp"
            valueFields={["Grid", "Load", "Solar", "Demand", "DayPeak"]}
            disabled={!powerData[BuildingID as string]?.length}
            timezone="America/New_York"
          />
        </CardContent>
      </Card>
    </LocalizationProvider>
  );
}

export default PowerGraphHandler;



