import React from 'react';
import { useParams } from 'react-router-dom';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Card } from '@mui/material';
import BuildingGraphHandler from '../handlers/powerGraph-handler';
import { PowerCard } from '../cards/power-card';
const ResponsiveGridLayout = WidthProvider(Responsive);

const BuildingGrid: React.FC = () => {
  const { BuildingID } = useParams();

  const layouts = {
    lg: [
      { i: 'graph', x:0, y: 1, w: 9, h: 5, minW: 6, minH: 5 },
      { i: 'power-card', x: 0, y: 0, w: 9, h: 1, minW: 2, minH: 5 },
    ],
    md: [
      { i: 'graph', x: 0, y: 1, w: 6, h: 5, minW: 4, minH: 5 },
      { i: 'power-card', x: 0, y: 0, w: 6, h: 1, minW: 2, minH: 5 },
    ],
    sm: [
      { i: 'graph', x: 0, y: 2, w: 3, h: 4, minW: 2, minH: 4 },
      { i: 'power-card', x: 0, y: 0, w: 3, h: 2, minW: 2, minH: 4 },
    ],
    xs: [
      { i: 'graph', x: 0, y: 3, w: 1, h: 4, minW: 1, minH: 4 },
      { i: 'power-card', x: 0, y: 0, w: 1, h: 3, minW: 1, minH: 4 },
    ]
  };

  return (
    <ResponsiveGridLayout 
      className="layout" 
     layouts={layouts} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
    >
      <div key="power-card" className='card-gauge'>
        <PowerCard BuildingID={BuildingID} />
      </div>
      <div key="graph" className='card-gauge'>
        <Card style={{ minHeight: "500px", width: "100%" }}>
          <BuildingGraphHandler BuildingID={BuildingID} />
        </Card>
      </div>
    </ResponsiveGridLayout>
  );
};

export default BuildingGrid;