import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useAtom } from 'jotai';
import { powerAtom } from '../atoms-types/power';
import { CircularProgress } from '@mui/material';
import { BuildingIDProps } from '../atoms-types/chargers';
import moment from 'moment';

const PowerGraph: React.FC<BuildingIDProps> = ({ BuildingID }) => {
  const [powerDataMap] = useAtom(powerAtom);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  const powerData = BuildingID && Array.isArray(powerDataMap[BuildingID])? powerDataMap[BuildingID] : [];


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      const chartElement = document.querySelector('.echarts-for-react');
      if (chartElement) {
        const echartsInstance = (chartElement as any).__ECharts__;
        if (echartsInstance) {
          echartsInstance.resize();
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!powerData) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}>
        <CircularProgress style={{ color: 'firebrick' }} />
        <h3 style={{ color: 'text.primary', marginTop: '20px' }}>Waiting for data...</h3>
      </div>
    );
  }

  const xAxisData: string[] = [];
  const verticalLines: any[] = [];
  let previousDate = "";

  powerData.forEach((b, index) => {
    const timestamp = moment.utc(b.Timestamp).tz('America/New_York');
    const formattedTime = timestamp.format('HH:mm:ss');
    const currentDate = timestamp.format('M/D');

    xAxisData.push(formattedTime);

  if (currentDate !== previousDate && timestamp.hour() === 0 && timestamp.minute() === 0) {
      verticalLines.push({
        xAxis: index,
        lineStyle: {
          color: 'lightgrey',
          type: 'dashed',
        },
        label: {
          formatter: currentDate,
          position: 'end',
          fontSize: 10,
          color: 'grey',
        },
        tooltip: {
          formatter: `Date: ${currentDate}`,
        },
      });
      previousDate = currentDate;
    }
  });

  const Grid: number[] = powerData.map(b => b.Grid ?? 0);
  const Load: number[] = powerData.map(b => b.Load ?? 0);
  const Solar: number[] = powerData.map(b => b.Solar ?? 0);
  const Demand: number[] = powerData.map(b => b.Demand ?? 0);
  const DayPeak: number[] = powerData.map(b => b.DayPeak ?? 0);

  const option = {
    // baseOption: {
    //     timeline: {},
    //     series: [],
    // },
    backgroundColor: 'transparent',
    // title: {
    //   show: !isMobile,
    //   text: 'Building: Data Over Time',
    //   textStyle: {
    //     color: 'grey',
    //   },
    // },
    tooltip: {
      show: !isMobile,
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
    },
    legend: {
      data: ['Grid', 'Load', 'Solar', 'Demand', 'DayPeak'],
      textStyle: {
        color: 'grey',
      },
      bottom: 0,
      padding: [5, 0, 0, 0]
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        color: 'grey',
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: 'grey',
      },
    },
    series: [
      {
        name: 'Grid',
        type: 'line',
        data: Grid,
        lineStyle: { color: 'firebrick' },
        itemStyle: { color: 'firebrick' },
        markLine: {
          symbol: 'none',
          data: verticalLines,
        },
      },
      {
        name: 'Load',
        type: 'line',
        data: Load,
        lineStyle: {
          color: 'steelblue'
        },
        itemStyle: {
          color: 'steelblue'
        }
      },
      {
        name: 'Solar',
        type: 'line',
        data: Solar,
        lineStyle: {
          color: 'gold'
        },
        itemStyle: {
          color: 'gold'
        }
      },
      {
        name: 'Demand',
        type: 'line',
        data: Demand,
        lineStyle: {
          color: 'slateblue'
        },
        itemStyle: {
          color: 'slateblue'
        }
      },
      {
        name: 'DayPeak',
        type: 'line',
        data: DayPeak,
        lineStyle: {
          color: 'seagreen'
        },
        itemStyle: {
          color: 'seagreen'
        }
      }
    ],
  };

  return(
    <ReactECharts 
      option={option} 
      className="cancelSelectorName" 
      style={{ height: '100%', width: '100%' }} 
      opts={{ renderer: 'canvas' }}
    />
  )
};

export default PowerGraph;





