import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout'
import { CardContent, Typography, Card, Box, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const DashboardGrid: React.FC = () => {
  const navigate = useNavigate();

  const layout: Layout[] = [
    { i: 'a', x: 0, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
    { i: 'b', x: 1, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
    { i: 'c', x: 2, y: 0, w: 1, h: 1, minW: 1, minH: 1 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 3, md: 3, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
    >
      <div key="a" style={{ minHeight: "300px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
            </Box>
            <br />
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Charging Summary
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => navigate('/fleet/charging/pool')}>Go to Charging</Button>
          </CardActions>
        </Card>
      </div>
      <div key="b" style={{ minHeight: "300px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
            </Box>
            <br />
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Power Summary
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => navigate('/power/pool')}>Go to Power</Button>
          </CardActions>
        </Card>
      </div>
      <div key="c" style={{ minHeight: "300px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
            </Box>
            <br />
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Environment Summary
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => navigate('/environment')}>Go to Environment</Button>
          </CardActions>
        </Card>
      </div>
    </ResponsiveGridLayout>
  );
};

export default DashboardGrid;