import React, { useEffect, useState } from 'react';
import { Card, CardContent, Chip, Typography, Tooltip, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BuildingIDProps } from '../atoms-types/chargers';
import { powerAtom, Power } from '../atoms-types/power';
import { useAtom } from 'jotai';
import useWebSocket from '../wsHook';

export const PowerCard: React.FC<BuildingIDProps> = ({ BuildingID }) => {
  const [powerData] = useAtom(powerAtom);
  const [localPowerData, setLocalPowerData] = useState<Power | null>(null);

  const { messages } = useWebSocket('wss://odp.golf/websocket', ['power']);

  // Initialize local state with powerAtom data when component mounts
  useEffect(() => {
    if (BuildingID && powerData[BuildingID]?.length > 0) {
      setLocalPowerData(powerData[BuildingID][powerData[BuildingID].length - 1]);
    }
  }, [BuildingID, powerData]);

  // Update local state with WebSocket messages
  useEffect(() => {
    messages.forEach((messageObj) => {
      const { channel, message } = messageObj;

      if (channel === 'power') {
        console.log("🔌 New power data for", BuildingID, message);
        try {
          if (message && typeof message === "object" && BuildingID !== undefined) {
            setLocalPowerData(message as Power);
          }
        } catch (error) {
          console.error('Failed to process power message:', error);
        }
      }
    });
  }, [messages, BuildingID]);

  if (!BuildingID) return null;

  if (!localPowerData) {
    return <Typography>Loading power data for {BuildingID}...</Typography>;
  }

  const tooltipContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body2">PhA: {localPowerData.PhA}</Typography>
      <Typography variant="body2">PhB: {localPowerData.PhB}</Typography>
      <Typography variant="body2">PhC: {localPowerData.PhC}</Typography>
      <Typography variant="body2">Solar: {localPowerData.Solar}</Typography>
      <Typography variant="body2">Load: {localPowerData.Load}</Typography>
      <Typography variant="body2">Demand: {localPowerData.Demand}</Typography>
      <Typography variant="body2">DayPeak: {localPowerData.DayPeak}</Typography>
    </div>
  );

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {BuildingID}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography>Grid</Typography>
            <Chip label={`${localPowerData.Grid}`} sx={{ backgroundColor: 'steelblue', color: 'white' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography>Import</Typography>
            <Chip label={`${localPowerData.DaykWhImport}`} sx={{ backgroundColor: 'steelblue', color: 'white' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography>Export</Typography>
            <Chip label={`${localPowerData.DaykWhExport}`} sx={{ backgroundColor: 'steelblue', color: 'white' }} />
          </div>
          <Tooltip title={tooltipContent} placement="top" arrow>
            <IconButton size="small" sx={{ marginLeft: 'auto' }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
    </Card>
  );
};

