import { atom } from 'jotai';

export interface EsensorQuery {
  deviceEUI: string;
  jInfo: string;
}

export interface SensorInfo {
  deviceEUI: string;
  Type: string;
  Class: string;
  Group: string;
  Display: string;
  Location: string;
  jInfo: string;
}

export interface ParsedSensorInfo {
  deviceEUI: string;
  Type: string;
  Class: string;
  Group: string;
  Display: string[];
  Location: string;
  jInfo: JInfo;
}

export type Esensor = {
  deviceEUI: string;
} & JInfo;

export type JInfo = Kiln | RainGauge | RiverHeight | Tank | Weather | SoilMt

export interface EsensorChart {
  timestamp: string;
  [key: string]: string | number | null;
}

// Kiln deviceEUI: "A84041F5F1881CF7"
export type Kiln = {
  BattStatus: number;
  Humidity: number;
  ITemp: number;
  Vbat: number;
  XTemp: number;
  XType: number;
  XTypeName: string;
};

// RG deviceEUI: "2CF7F1C0609001CE"
export type RainGauge = {
  Hourly: number;
  Inches: number;
};

// RH deviceEUI: "2CF7F1C06090018F"
export type RiverHeight = {
  Height: number;
};

// Tank deviceEUI: "2CF7F1C06090013B"
export type Tank = {
  Height: number;
  Raw: number;
  Gallons: number;
  FuelVolume: number;
};

// Weather deviceEUI: "2CF7F1C06090023A"
export type Weather = {
  TempC: number;
  TempF: number;
  TempHex: string;
  Humidity: number;
  BaroPress: number;
  BaroHex: number;
  WindDir: number;
  WindMax: number;
  WindAvg: number;
  HeatTemp: number;
  State: number;
};

export type SoilMt = {
  ECmscm: number;
  TempC: number;
  TempF: number;
  TempHex: string;
  Moisture: number;
};

export interface DeviceEUIProps {
  deviceEUI: string;
  Display: string[];
  Class?: string;
}

export const eSensorsAtom = atom<Esensor[]>([]);

export const eSensorChartAtom = atom<EsensorChart[]>([]);

export const sensorInfoAtom = atom<{ [deviceEUI: string]: ParsedSensorInfo[] }>({});