import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { eventsAtom, Event } from '../atoms-types/events';
import ConvertTime from '../converters/timestampConverter';
import useWebSocket from '../wsHook';
import EventNewsfeedGrid from '../grids/eventNewsfeedGrid';
import EventTable from '../charts-graphs/eventTable';

export default function EventNewsfeedHandler() {
  const { messages } = useWebSocket('wss://odp.golf/websocket', ['event']);
  const setEventData = useSetAtom(eventsAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['fallbackEvents'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<Event[]>('https://odp.golf/api/fallback-events');
      const data = response.data;
      UpdateEventData(data);
      console.log("all fallback events: ", data);
      return data;
    }
  });

  function UpdateEventData(data: Event[]) {
    if (!data || data.length === 0) {
      console.error("No data available to update events information");
      return;
    }

    console.log("All fallback event data:", data);

    const updatedEventData = data?.map(event => ({
      ...event,
      Timestamp: ConvertTime(event.Timestamp)
    }));
    setEventData(updatedEventData);
  };

  useEffect(() => {
    messages.forEach((messageObj) => {
      const { channel, message } = messageObj;

      if (channel === 'event') {
        console.log("received new message from: ", channel);
        try {
          if (message && typeof message === "object") {
            console.log('Updating event data:', message);
            setEventData((prev) => [
              ...prev, 
              message as Event
            ]);
          } else {
            console.error('Invalid event payload format:', message);
          }
        } catch (error) {
          console.error('Failed to parse message:', error);
        }
      }
    });
  }, [messages, setEventData]);

    const renderLoadingOrError = () => {
      if (isFetching) {
        return <p>Loading...</p>;
      } else if (isError) {
        return <p>Error: {error.message}</p>;
      }
      return null;
    };

  return (
      <div>
        {renderLoadingOrError()}
        <EventTable/>
      </div>
    );
  };