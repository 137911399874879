import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout'
import { Divider, Card, Typography, CardContent, Box } from '@mui/material';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const UnderConstruction: React.FC = () => {

  const layout: Layout[] = [
    { i: 'a', x: 0, y: 0, w: 9, h: 9, minW: 9, minH: 6 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="a" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'success.main' }}>Sorry! This page is currently under development</Typography>
            </Box>
          </CardContent>
        </Card>
      </div>
    </ResponsiveGridLayout>
  );
};

export default UnderConstruction;